<template>
  <div class="prodcuts-wrap">
    <pageTitle pageTitle="商品"></pageTitle>
    <div class="prodcuts-wrap-container">
      <vh-tabs v-model="tabType" @tab-click="handleTabClick">
        <vh-tab-pane label="商品列表" name="list">
          <product-list v-if="tabType == 'list'" :isInWebinar="isInWebinar"></product-list>
        </vh-tab-pane>
        <vh-tab-pane name="coupon" v-if="!enableThirdCouponAndOrder">
          <span slot="label">
            优惠券列表
            <vh-tooltip effect="dark" placement="right" v-tooltipMove>
              <div slot="content">优惠券适用于「平台购买」方式的商品</div>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </span>
          <coupon-list v-if="tabType == 'coupon'" :isInWebinar="isInWebinar"></coupon-list>
        </vh-tab-pane>
        <vh-tab-pane name="order" v-if="!enableThirdCouponAndOrder">
          <span slot="label">
            订单列表
            <vh-tooltip effect="dark" placement="right" v-tooltipMove>
              <div slot="content">仅展示设置「平台购买」「自定义购买」方式的订单数据</div>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </span>
          <order-list v-if="tabType == 'order'" :isInWebinar="isInWebinar"></order-list>
        </vh-tab-pane>

        <vh-tab-pane label="交易数据" name="trade" v-if="isInWebinar">
          <TradeStats ref="refTradeStats" />
        </vh-tab-pane>

        <vh-tab-pane label="其他设置" name="other">
          <other-settings
            v-if="tabType == 'other'"
            :isInWebinar="isInWebinar"
            :userId="userId"
            :webinarId="isInWebinar ? $route.params?.str : undefined"
            :enableThirdCouponAndOrder="enableThirdCouponAndOrder"
            @toAccountSetting="handleToAccountSetting"
          ></other-settings>
        </vh-tab-pane>
      </vh-tabs>
    </div>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4 && isInWebinar"
    ></begin-play>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import ProductList from './list.vue';
  import OrderList from './order.vue';
  import CouponList from './coupon.vue';
  import { OtherSettings } from '@vhcl/product';
  import beginPlay from '@/components/beginBtn';
  import { sessionOrLocal } from '@/utils/utils';
  import TradeStats from './TradeStats.vue';

  export default {
    name: 'ProductIndex',
    components: {
      PageTitle,
      ProductList,
      OrderList,
      OtherSettings,
      beginPlay,
      CouponList,
      TradeStats
    },
    data() {
      return {
        webinarState: sessionStorage.getItem('webinarState'),
        tabType: 'list', // list order other coupon
        tabs: ['list', 'coupon', 'order', 'other'],
        userId: JSON.parse(sessionOrLocal.get('userId'))
      };
    },
    computed: {
      // true：活动下，false：账号下
      isInWebinar() {
        return this.$route.path.indexOf('/live/productSet') > -1;
      },
      // 是否显示优惠券列表和订单列表
      enableThirdCouponAndOrder() {
        // 开启了用户账号下的商品权限 & 且未开启使用三方的优惠券和订单
        if (this.isInWebinar) {
          return (
            this.$store.state.webinarPermission?.['product_show'] == 0 ||
            this.$store.state.webinarPermission?.['enable_third_coupon_and_order'] == 1
          );
        } else {
          return (
            this.$store.state.permission?.['product_show'] == 0 ||
            this.$store.state.permission?.['enable_third_coupon_and_order'] == 1
          );
        }
      }
    },
    methods: {
      handleToAccountSetting() {
        const url = `${window.location.origin}${process.env.VUE_APP_WEB_KEY}/interactive/products?tab=3`;
        window.open(url, '_blank');
      },
      handleTabClick() {
        if (this.tabType === 'trade') {
          this.$refs['refTradeStats'].handleSearch();
        }
      }
    },
    created() {
      if (this.$route.query.tab) {
        if (this.$route.query.tab === 'trade' && this.isInWebinar) {
          this.tabType = this.$route.query.tab;
        } else {
          this.tabType = this.tabs[this.$route.query.tab];
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .prodcuts-wrap {
    &-container {
      background: #fff;
    }
    .saasicon_help_m {
      color: #999999;
      &:hover {
        color: #666;
      }
    }
  }
</style>
